import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { emails } from "pages/apps/Email/data";
import { faker } from '@faker-js/faker';

interface UserData {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  roles: any[];
  permissions: any[];
  token: string;
}

const meta = {
  "current_page": 1,
  "from": 1,
  "last_page": 31,
  "links": [
      {
          "url": null,
          "label": "&laquo; Previous",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=1",
          "label": "1",
          "active": true
      },
      {
          "url": "http://127.0.0.1:8083?page=2",
          "label": "2",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=3",
          "label": "3",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=4",
          "label": "4",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=5",
          "label": "5",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=6",
          "label": "6",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=7",
          "label": "7",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=8",
          "label": "8",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=9",
          "label": "9",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=10",
          "label": "10",
          "active": false
      },
      {
          "url": null,
          "label": "...",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=30",
          "label": "30",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=31",
          "label": "31",
          "active": false
      },
      {
          "url": "http://127.0.0.1:8083?page=2",
          "label": "Next &raquo;",
          "active": false
      }
  ],
  "path": "http://127.0.0.1:8083",
  "per_page": 10,
  "to": 10,
  "total": 302
};

var mock = new MockAdapter(axios);
mock.restore(); // deactive mock adapter

export function configureFakeBackend() {
  let users: UserData[] = [
    {
      id: 1,
      email: "user@gmail.com",
      password: "user@gmail.com",
      firstName: "Test",
      lastName: "Admin",
      roles: ["Admin"],
      permissions: [],
      token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI",
    },
  ];

  mock.onPost("/auth/login").reply(function (config) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // get parameters from post request
        let params = JSON.parse(config.data);

        // find if any user matches login credentials
        let filteredUsers = users.filter((user) => {
          return (
            user.email === params.email &&
            user.password === params.password
          );
        });

        if (filteredUsers.length) {
          // if login details are valid return user details and fake jwt token
          let user = filteredUsers[0];
          resolve([200, {
            status: true,
            message: 'Success',
            result: {
              user,
              token: user.token
            },
          }]);
        } else {
          // else return error
          resolve([401, { message: "email or password is incorrect" }]);
        }
      }, 1000);
    });
  });

  mock.onPost("/auth/register").reply(function (config) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // get parameters from post request
        let params = JSON.parse(config.data);

        // add new users
        let [firstName, lastName] = params.fullname.split(" ");
        let newUser: UserData = {
          id: users.length + 1,
          email: firstName,
          password: params.password,
          firstName: firstName,
          lastName: lastName,
          roles: ["Admin"],
          permissions: [],
          token:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI",
        };
        users.push(newUser);

        resolve([200, newUser]);
      }, 1000);
    });
  });

  mock.onPost("/auth/forget-password").reply(function (config) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // get parameters from post request
        let params = JSON.parse(config.data);

        // find if any user matches login credentials
        let filteredUsers = users.filter((user) => {
          return user.email === params.email;
        });

        if (filteredUsers.length) {
          // if login details are valid return user details and fake jwt token
          let responseJson = {
            message:
              "We've sent you a link to reset password to your registered email.",
          };
          resolve([200, responseJson]);
        } else {
          // else return error
          resolve([
            401,
            {
              message:
                "Sorry, we could not find any registered user with entered email",
            },
          ]);
        }
      }, 1000);
    });
  });

  // Contact Group
  const contactUrl1 = new RegExp(`/contact-group*`);
  mock.onAny(contactUrl1).reply(function (config) {
    return new Promise(function (resolve, reject) {
      const contact = faker.helpers.multiple(() => {
        return {
          id: faker.string.uuid(),
          first_name: faker.person.firstName(),
          last_name: faker.person.lastName(),
          email: faker.internet.email(),
          phone: faker.phone.number(),
        }
      }, {
        count: 100,
      });
      
      setTimeout(function () {
        resolve([200, {
          status: true,
          message: 'Success',
          meta: meta,
          result: [
            {
              id: faker.string.uuid(),
              name: 'School Contacts',
              campaign_name: 'Broadcast Info',
              created_at: '10:30 4 Jan 2024',
              data: contact,
            }
          ]
        }]);
      }, 1000);
    });
  });

  // Contact
  const contactUrl2 = new RegExp(`/contact*`);
  mock.onAny(contactUrl2).reply(function (config) {
    return new Promise(function (resolve, reject) {
      const contact = faker.helpers.multiple(() => {
        return {
          id: faker.string.uuid(),
          first_name: faker.person.firstName(),
          last_name: faker.person.lastName(),
          email: faker.internet.email(),
          phone: faker.phone.number(),
        }
      }, {
        count: 100,
      });
      
      setTimeout(function () {
        resolve([200, {
          status: true,
          message: 'Success',
          meta: meta,
          result: contact
        }]);
      }, 1000);
    });
  });

  // Campaign
  const campaignUrl = new RegExp(`/campaign*`);
  mock.onAny(campaignUrl).reply(function (config) {
    return new Promise(function (resolve, reject) {
      const campaign = faker.helpers.multiple(() => {
        return {
          id: faker.string.uuid(),
          campaign_name: 'Campaign '+faker.company.name(),
          contact_type: faker.helpers.arrayElement(['Individual', 'Contact Group']),
          status: faker.helpers.arrayElement(['Delivery', 'Pending', 'Processing']),
        }
      }, {
        count: 100,
      });
      
      setTimeout(function () {
        resolve([200, {
          status: true,
          message: 'Success',
          meta: meta,
          result: campaign
        }]);
      }, 1000);
    });
  });

  mock.onAny().reply(function (config) {
    console.log('config', config);
    return [200, {}];
  });
}
